import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="Introduzione alla Trascrizione Live (Real-time)" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Home" />
          </Link>
          <BlogHeader
            title="Introduzione alla Trascrizione Live (Real-time)"
            date="1 Ottobre 2021"
          />
          <p>
            La maggior parte della nostra vita professionale ruota attorno alla comunicazione.
            Scriviamo verbali di meeting, email e ci scambiamo idee.
          </p>
          <p>
            Con una fitta agenda e centinaia di idee che occupano la nostra mente
            e il nsotro tempo, è facile perdere qualche informazione.
          </p>
          <p>
            Per molti di voi, VoxRec vi sta già aiutando con la trascrizione di
            meeting, email, consulenze, interviste e altre attività in cui
            le informazioni devono essere acquisite ed elborate.
          </p>
          <p>
            Alcuni non hanno ancora integrato la tecnologia da voce a testo nella loro comunicazione
            quotidiana, cosa che svantaggia il loro flusso di lavoro senza che 
            se ne rendano conto.
          </p>
          <p>
            Per molte persone, la comunicazione orale è una sfida. Le persone con 
            disabilità, dislessia e quelli che non parlano la tua lingua
            madre hanno tutti difficoltà a capire quello che stai dicendo, cosa
            che non dovrebbe accadere.
          </p>
          <p>
            Oggi stiamo ampliando il nostro attuale servizio da voce a testo,
            introducendo la Trascrizione Live nella nostra app. La registrazione viene
            come sempre acquisita, ma la trascrizione viene riprodotta sullo schermo in
            tempo reale.
          </p>
          <p>
            Confidiamo che questa caratteristica aiuterà i nostri futuri utilizzatori e che venga inclusa 
            soprattutto in quegli ambienti e attività in cui altrimenti si avrebbe
            difficoltà a parteciparvi.
          </p>
          <BlogBanner
            title="Trascrizione da Voce a Testo Istantanea"
            text="Vedi e leggi cos'è stato detto senza l'aiuto di un interprete e senza aspettare. Prova la Trascrizione Live di VoxRec."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec su iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>Accessibilità Digitale con la Trascrizione da Voce a Testo</h2>
          <p>
            Secondo l'Organizzazione Mondiale della Salute il numero di persone con problemi di 
            udito e i non udenti ruoterebbe attorno ai 466 milioni a livello globale.
          </p>
          <p>
            I governi stanno spingendo ora più che mai per la parità per 
            tutti. Le app di trascrizione in tempo reale possono portare questa visione
            alla realtà.
          </p>
          <p>
            Milioni di altre persone hanno difficoltà a scrivere al computer o a prendere 
            appunti. Che si tratti di dislessia, disgrafia, traumi, o altre
            disabilità che limitano i movimenti della mano o che colpiscono le abilità intellettuali, la 
            trascrizione live puiò aiutare le persone a partecipare a conversazioni, meeting ed eventi 
            online o offline.
          </p>
          <h2>Trascrizione Live in Meeting e Conferenze Internazionali</h2>
          <p>
            Viviamo in un'epoca in cui la maggior parte dei meeting e delle conferenze vede persone
            da diversi paesi. La trascrizione live aiuta i professionisti in
            riunioni internazionali a sentire e vedere di cosa
            si parla. Questo permette che si concentrino sugli argomenti e sulle idee invece
            di cercare di capire la lingua.
          </p>
          <h2>Sottotitoli in tempo reale in classe e a lezione</h2>
          <p>
            Professori e studenti potrebbero essere molto più efficienti se a lezione
            implementassero la tecnologia di riconoscimento vocale basata sull'IA.
          </p>
          <p>
            Studenti con disabilità o non fluenti nella lingua parlata possono 
            usare VoxRec per vedere come testo quello che sta dicendo l'oratore in tempo reale.
            Li aiuterà a imparare, capire e rispondere meglio al
            contesto della presentazione.
          </p>
          <p>
            Una volta finita la lezione, VoxRec invierà un'accurata
            trascrizione via email che potranno usare come appunti di studio.
          </p>
          <p>
            Allo stesso modo, nelle conferenze e presentazioni si possono utilizzare i sottotitoli in tempo reale per
             aiutare a elaborare le parole del relatore, espediente molto utile quando
             non c'è un interprete per non udenti e ipoudenti.
          </p>
          <h2>Prova la Trascrizione Vocale con VoxRec</h2>
          <p>
             Puoi attivare e provare la funzione di trascrizione in tempo reale sulla
             schermata di registrazione dell'app VoxRec. Tocca la faccia del robot (🤖),
             parla in modo naturale e guarda il testo che appare sullo schermo mentre stai
             parlando. Magia!
          </p>
          <p>
            Puoi regolare la dimensione del carattere direttamente sullo schermo per assicurarti che il
             testo sia di facile lettura.
          </p>
          <p>
            Quando interrompi la registrazione, sia l'audio che la
             trascrizione saranno disponibili per essere revisionati o esportati.
          </p>
          <p>
            Nota: la trascrizione in tempo reale non può essere inviata 
             automaticamente via email come con una normale trascrizione 
             cloud di un audio preregistrato.
          </p>
          <p>
            Per saperne di più, scopri il nostro aggiornamento{' '}
            <a href="/faq">FAQ sul nostro sito</a> o nell'app.
          </p>
          <p>
            Facci sapere cosa ne pensi della nuova funzione di trascrizione in tempo reale
             - tutti i feedback sono i benvenuti!
          </p>
        </div>
      </section>
    </LiveChatLayout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
